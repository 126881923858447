// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mileage-label {
  font-size: 0.75rem;
  color: var(--color-primary);
  border-radius: 6px;
  font-weight: 500;
}
.mileage-label.mileage-1000 {
  color: var(--color-warning);
}
.mileage-label.mileage-500 {
  color: var(--color-danger);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1pbGVhZ2UtbGFiZWwuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxrQkFBQTtFQUNBLDJCQUFBO0VBQ0Esa0JBQUE7RUFDQSxnQkFBQTtBQUNGO0FBQ0U7RUFDRSwyQkFBQTtBQUNKO0FBRUU7RUFDRSwwQkFBQTtBQUFKIiwiZmlsZSI6Im1pbGVhZ2UtbGFiZWwuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIubWlsZWFnZS1sYWJlbCB7XG4gIGZvbnQtc2l6ZTogMC43NXJlbTtcbiAgY29sb3I6IHZhcigtLWNvbG9yLXByaW1hcnkpO1xuICBib3JkZXItcmFkaXVzOiA2cHg7XG4gIGZvbnQtd2VpZ2h0OiA1MDA7XG5cbiAgJi5taWxlYWdlLTEwMDAge1xuICAgIGNvbG9yOiB2YXIoLS1jb2xvci13YXJuaW5nKTtcbiAgfVxuXG4gICYubWlsZWFnZS01MDAge1xuICAgIGNvbG9yOiB2YXIoLS1jb2xvci1kYW5nZXIpO1xuICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/mileage-label/mileage-label.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,2BAAA;EACA,kBAAA;EACA,gBAAA;AACF;AACE;EACE,2BAAA;AACJ;AAEE;EACE,0BAAA;AAAJ;AACA,grBAAgrB","sourcesContent":[".mileage-label {\n  font-size: 0.75rem;\n  color: var(--color-primary);\n  border-radius: 6px;\n  font-weight: 500;\n\n  &.mileage-1000 {\n    color: var(--color-warning);\n  }\n\n  &.mileage-500 {\n    color: var(--color-danger);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
