// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.car-cost-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}
.car-cost-form ion-input.sc-ion-input-ios-h:has([slot=end] > ion-text) .label-text-wrapper,
.car-cost-form ion-input.sc-ion-input-md-h:has([slot=end] > ion-text) .label-text-wrapper {
  min-width: 50%;
}
.car-cost-form ion-input.sc-ion-input-ios-h.input-label-placement-floating .native-input,
.car-cost-form ion-input.sc-ion-input-md-h.input-label-placement-floating .native-input {
  min-width: 50%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNhci1jb3N0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsYUFBQTtFQUNBLHNCQUFBO0VBQ0EsU0FBQTtFQUNBLGdCQUFBO0FBQ0Y7QUFJTTs7RUFDRSxjQUFBO0FBRFI7QUFNTTs7RUFDRSxjQUFBO0FBSFIiLCJmaWxlIjoiY2FyLWNvc3QuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2FyLWNvc3QtZm9ybSB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gIGdhcDogMTBweDtcbiAgbWFyZ2luLXRvcDogMjBweDtcblxuICBpb24taW5wdXQuc2MtaW9uLWlucHV0LWlvcy1oLFxuICBpb24taW5wdXQuc2MtaW9uLWlucHV0LW1kLWgge1xuICAgICY6aGFzKFtzbG90PVwiZW5kXCJdPmlvbi10ZXh0KSB7XG4gICAgICAubGFiZWwtdGV4dC13cmFwcGVyIHtcbiAgICAgICAgbWluLXdpZHRoOiA1MCU7XG4gICAgICB9XG4gICAgfVxuXG4gICAgJi5pbnB1dC1sYWJlbC1wbGFjZW1lbnQtZmxvYXRpbmcge1xuICAgICAgLm5hdGl2ZS1pbnB1dCB7XG4gICAgICAgIG1pbi13aWR0aDogNTAlO1xuICAgICAgfVxuICAgIH1cbiAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/pages/maintenance-cost-calculating/car-cost/car-cost.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;AACF;AAIM;;EACE,cAAA;AADR;AAMM;;EACE,cAAA;AAHR;AACA,o2BAAo2B","sourcesContent":[".car-cost-form {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  margin-top: 20px;\n\n  ion-input.sc-ion-input-ios-h,\n  ion-input.sc-ion-input-md-h {\n    &:has([slot=\"end\"]>ion-text) {\n      .label-text-wrapper {\n        min-width: 50%;\n      }\n    }\n\n    &.input-label-placement-floating {\n      .native-input {\n        min-width: 50%;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
